/* Container with dark background */
.myplan-container {
    background-color: #121212;
    min-height: 100vh;
    padding: 2rem;
    color: #ffffff;
}

/* Title for the page */
.myplan-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
}

/* Loading message */
.myplan-loading {
    text-align: center;
}

/* Grid container to layout cards responsively */
.myplan-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
}

/* Modern card style for each plan */
.myplan-plancard {
    background-color: #1e1e1e;
    border-radius: 15px;
    width: 320px;
    padding: 20px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #4caf50;
    /* Very thin green border */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.myplan-plancard:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

/* Card title with space between text and icon */
.myplan-card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Subtitle for additional info */
.myplan-card-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #b0b0b0;
}

/* Details text for plan features */
.myplan-card-detail {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #e0e0e0;
}

/* Styled button placed at the bottom of the card */
.myplan-purchased-button {
    background-color: #4caf50;
    border: none;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    text-align: center;
    border-radius: 10px;
    font-size: 1rem;
    cursor: not-allowed;
    transition: background-color 0.3s;
    width: 100%;
    margin-top: auto;
    /* push the button to the bottom */
    display: flex;
    align-items: center;
    justify-content: center;
}

.myplan-purchased-button:hover {
    background-color: #45a049;
}

.myplan-purchased-button svg {
    margin-left: 0.5rem;
}