/* Container with dark background */
.allplan-container {
    padding: 2rem;
    background-color: #121212;
    min-height: 100vh;
    color: #fff;
}

/* Page title styling */
.allplan-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.2rem;
}

/* Custom Tabs styling */
.allplan-custom-tabs .nav-tabs {
    margin-bottom: 1.5rem;
    z-index: 10;
    position: relative;
}

/* Styling for each tab (nav-link) */
.allplan-custom-tabs .nav-tabs .nav-link {
    color: #bbb;
    background-color: #1f1f1f;
    border: 2px solid #444;
    border-radius: 12px;
    margin-right: 1rem;
    padding: 12px 24px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover state for tabs */
.allplan-custom-tabs .nav-tabs .nav-link:hover {
    background-color: #2c2c2c;
    color: #fff;
}

/* Active tab styling: selected tab looks modern and distinct */
.allplan-custom-tabs .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #ff6600;
    border-color: #ff6600;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    font-weight: bold;
}

/* Grid container for cards */
.allplan-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 8rem;
}

/* Card styling */
.allplan-card {
    background-color: #1f1f1f;
    border-radius: 15px;
    width: 320px;
    padding: 20px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #007bff;
    /* Very thin blue border */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.allplan-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

/* Card title - plan name appears in blue */
.allplan-card-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #007bff;
    /* Blue for plan name */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Card subtitle */
.allplan-card-subtitle {
    font-size: 1.2rem;
    color: #bbb;
    margin-bottom: 1rem;
}

/* Card detail text */
.allplan-card-detail {
    font-size: 1rem;
    color: #ccc;
    margin-bottom: 0.5rem;
}

/* Button styling */
.allplan-button {
    padding: 12px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.allplan-button:hover {
    background-color: #0056b3;
}

/* Adjust tab-content margin if necessary */
.allplan-tab-content {
    margin-top: -5rem;
}

.purchased-badge {
    color: #28a745;
    margin-left: 10px;
}

.plan-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.allplan-button.purchased {
    background-color: #6c757d;
    cursor: not-allowed;
}

.plan-details {
    text-align: left;
    margin: 15px 0;
}

.plan-details p {
    margin: 8px 0;
    font-size: 0.9rem;
}

/* Existing AllPlan CSS remains... */

/* New rule for Buy Again button */
.allplan-button.buy-again {
    background-color: #28a745;
    /* Green color */
    cursor: pointer;
}

/* Optionally, update hover for buy-again */
.allplan-button.buy-again:hover {
    background-color: #218838;
}